<template>
  <cui-acl :roles="['supporttables']" redirect>
    <ListWithSearch
      title="Tabeller"
      placeholder="Søk..."
      :showSettings="['underDevelopment'].some(r => $store.state.user.roles.indexOf(r) >= 0)"
      settingsPath="/tools/supporttables/settings"
      :listData="tables"
      target="/tools/supporttables/edit/"
    />
  </cui-acl>
</template>

<script>
import cuiAcl from '@/components/cleanui/system/ACL'
import ListWithSearch from '@/components/custom/visual/ListWithSearch'
import { firestore } from '@/services/firebase'

export default {
  components: {
    cuiAcl,
    ListWithSearch,
  },
  data() {
    return {
      tables: [],
      employees: [],
      searchText: '',
    }
  },
  mounted() {
    let ref = firestore.collection('supportTables')
    if (!['underDevelopment'].some(r => this.$store.state.user.roles.indexOf(r) >= 0)) {
      ref = ref.where('access', 'array-contains', this.$store.state.user.id)
    }
    ref.onSnapshot(snapshot => {
      this.tables = []
      snapshot.forEach(doc => {
        this.tables.push({
          id: doc.id,
          title: doc.data().title,
          subtext: doc.data().description,
          description: doc.data().description,
          dataStructure: doc.data().dataStructure,
        })
      })
      this.oldData = this.tables
    })
  },
}
</script>
